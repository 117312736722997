import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import { CapGroupPageLayout, Panel, Disclosures } from '../../components'

import Highcharts from 'highcharts'
import HighchartsMore from 'highcharts/highcharts-more'
import HighchartsReact from 'highcharts-react-official'

import colors from '../../styles/colors'

if (typeof window !== `undefined`) {
  HighchartsMore(Highcharts);
}

const overviewDetails = [
  { 
    id: 'capG3M',
    name: 'Milliman - Capital Group Hedged U.S. Growth Fund',
    description: `Investment ${process.env.GATSBY_CLIENT === 'milliman' ? 'Objective' : 'Description'}: The Fund seeks to provide upside participation in the price appreciation, up to a cap (determined at fund term start date), as well as dividends, from a portfolio of growth-oriented equity securities selected by Capital Group, prior to taking into account any fees or expenses. The Fund also seeks to provide a level of protection against equity security price losses in the form of a 15% extended buffer (-5% to -20%). The upside appreciation and downside protection reset every 3 months.`,
    payoffProfile: [[-30, -15],[-20, -5], [-5, -5], [0, 0], [5,5], [30, 5]],
    color: colors.overviewColors.capG3M
  },
  {
    id: 'capIG3M',
    name: 'Milliman - Capital Group Hedged U.S. Income and Growth Fund',
    description: `Investment ${process.env.GATSBY_CLIENT === 'milliman' ? 'Objective' : 'Description'}: The Fund seeks to provide upside participation in the price appreciation, up to a cap (determined at fund term start date), as well as dividends, from a portfolio of growth- and income-oriented equity securities selected by Capital Group, prior to taking into account any fees or expenses. The Fund also seeks to provide a level of protection against equity security price losses in the form of a 15% extended buffer (-5% to -20%). The upside appreciation and downside protection reset every 3 months.`,
    payoffProfile: [[-30, -15],[-20, -5], [-5, -5], [0, 0], [5,5], [30, 5]],
    color: colors.overviewColors.capIG3M
  }
];

const Fund = ({ name, description, description2, payoffProfile, payoffProfile2, color = '#0080E2', color2 = '#0080E2', grayColor = '#AAAAAA', areaColor = '#EEF0F1', filter }) => {

  const options = {
    credits: { enabled: false },
    title: { text: '' },
    chart: { height: 250, width: 250, backgroundColor: 'transparent' },
    series: [],
    xAxis: {
      lineWidth: 0,
      tickLength: 0,
      labels: { enabled: false },
      min: -30,
      max: 30,
      plotLines: [{ value: 0, width: 1 }]
    },
    yAxis: {
      title: { text: null },
      gridLineWidth: 0,
      labels: { enabled: false },
      plotLines: [{ value: 0, width: 1 }],
      min: -30,
      max: 30
    },
    tooltip: { enabled: false },
    legend: { enabled: false },
    plotOptions: { series: { enableMouseTracking: false }}
  };

  options.series.push({ type: 'line', color: grayColor, dashStyle: 'dash', marker: { enabled: false }, data: [[-30, -30], [30, 30]] });
  if (payoffProfile2) {
    options.series.push({ type: 'arearange', color: areaColor, marker: { enabled: false }, data: payoffProfile.map((v, i) => [v[0], v[1], payoffProfile2[i][1]]) });
    options.series.push({ type: 'line', color: color2, marker: { enabled: false }, data: payoffProfile2 });
  }
  options.series.push({ type: 'line', color: color, marker: { enabled: false }, data: payoffProfile });

  return <Panel key="summary" className="fund">
    <Row className="align-items-center">
      <Col>
        <h4>{name}</h4>
        <p>{description}</p>
        {description2 && <p>{description2}</p>}
        <Link className="btn btn-primary" to={`/capgroup/funds${filter ? `?${filter.join('&')}` : ''}`}>View Funds</Link>
      </Col>
      <Col xs="auto" className="d-none d-lg-block">
        <HighchartsReact highcharts={Highcharts} options={options} />
      </Col>
    </Row>
  </Panel>
}

const IndexPage = () => {
  if (process.env.GATSBY_CAPGROUP_FUNDS !== 'true')
    return null;

  return <CapGroupPageLayout id="overview" title="Overview" className="page-overview">

    <Container>
      <div>

        <Row className="justify-content-center">
          <Col className="lead">
            <div>
              {process.env.GATSBY_CLIENT === 'milliman' ?
                <>
                  Milliman FRM's Hedged Equity Strategies and Funds are featured variable investment options within Advanced Outcomes Annuity<sup>&#174;</sup>.
                  This variable annuity is intended as a long-term retirement savings vehicle for a portion of client portfolios.
                </> : <>
                  The hedged equity funds are managed by Milliman Financial Risk Management and sub-advised by Capital Group<sup>&#174;</sup>, home of American Funds<sup>&#174;</sup>.
                  As actively managed funds, these strategies offer you access to a diverse portfolio of U.S.-based equities with some protection from portfolio underperformance.
                </>
              }
            </div>
          </Col>
        </Row>

        {overviewDetails.map(od =>
          <Fund key={od.id} {...od} />
        )}

        <Disclosures isCapGroup />
      </div>
    </Container>

  </CapGroupPageLayout>
}

export default IndexPage
