let colors;

if (process.env.GATSBY_CLIENT === 'milliman') {
    colors = {
        refAsset: '#666766',
        refAsset2: '#BBBBBB',
        total: '#00A562',
        equity: '#0080E2',
        fixedIncomeContribution: '#E8651F',

        refAssetText: '#FFFFFFEE',
        refAsset2Text: '#000000CC',
        totalText: '#000000CC',
        equityText: '#000000CC',
        fixedIncomeContributionText: '#000000CC',

        area: '#E6EBF5AA',

        fontFamily: '"Benton Sans", Arial, Helvetica, sans-serif',
        backgroundColor: '#fff',
        borderColor: '#d8d8d8',
        borderRadius: 20,
        padding: 16,

        overviewColors: {
            buffPar6M: '#20A764',
            buffPar6Y: '#70BD8F',
            parPar6M: '#396A91',
            parPar6Y: '#004977',
            buffSpr1Y: '#F9A51B',
            floorPar1Y: '#A61D3E',
            buffCapNas1Y: [ '#0080E2', '#004977' ],
            buffCapRuss1Y: [ '#0080E2', '#004977' ],
            buffCapMSCI1Y: [ '#0080E2', '#004977' ],
            buffTrig6M: '#F9A51B',
            
            capG3M: '#20A764',
            capIG3M: '#70BD8F',

            grayColor: '#AAA'
        }
    };
}
else {
    colors = {
        refAsset: '#C053EF',
        refAsset2: '#C053EF',
        total: '#3562FF',
        equity: '#008B73',
        fixedIncomeContribution: '#EC6020',

        refAssetText: '#FFF',
        refAsset2Text: '#FFF',
        totalText: '#FFF',
        equityText: '#FFF',
        fixedIncomeContributionText: '#FFF',

        area: '#E0E0FB',

        fontFamily: '"TT Norms Pro", Arial, sans-serif;',
        backgroundColor: '#fff',
        borderColor: '#d8d8d8',
        borderRadius: 20,
        padding: 16,

        overviewColors: {
            buffPar6M: '#4100CA',
            buffPar6Y: '#4100CA',
            parPar6M: '#4100CA',
            parPar6Y: '#4100CA',
            buffSpr1Y: '#4100CA',
            floorPar1Y: '#4100CA',
            buffCapNas1Y: [ '#4100CA', '#20113e' ],
            buffCapRuss1Y: [ '#4100CA', '#20113e' ],
            buffCapMSCI1Y: [ '#4100CA', '#20113e' ],
            buffTrig6M: '#4100CA',
            
            capG3M: '#4100CA',
            capIG3M: '#4100CA',

            grayColor: '#666'
        }
    };
}

export default colors;
